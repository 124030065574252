import BasePlugin from '../BasePlugin'

export default class CheckApplicationResubmissionCommand extends BasePlugin {
  async execute () {
    let requestId = {
      'requestId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CheckApplicationResubmissionCommand`,
      requestId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
